import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import LangButton from "../LangButton"
import Logo from "../Logo"
import { IconHamburger } from "../utils/Icons/CustomIcons"
import styles from "./Header.module.scss"

const Header = () => {
  const { t } = useTranslation()
  const location = useLocation()
  return (
    <header className={styles.header}>
      <a href="https://landing.mazoom.sa" className={styles.logo}>
        <Logo fillColor="#30302D" />
      </a>

      <nav className={styles.navBar}>
        <ul>
          <li>
            <a href="https://app.mazoom.sa">{t("header.nav.login")}</a>
          </li>
          <li>
            <a href="https://www.landing.mazoom.sa">{t("header.nav.home")}</a>
          </li>
          <li>
            <a href={`${location.pathname}${location.search}`}>{t("header.nav.tryMazoomInvitations")}</a>
          </li>
          <li>
            <a href="https://www.landing.mazoom.sa">{t("header.nav.features")}</a>
          </li>
          <li>
            <a href="https://www.landing.mazoom.sa">{t("header.nav.customers")}</a>
          </li>
          <li>
            <a href="https://www.landing.mazoom.sa">{t("header.nav.pricing")}</a>
          </li>
          <li>
            <a href="https://www.landing.mazoom.sa">{t("header.nav.contactUs")}</a>
          </li>
        </ul>
      </nav>

      <div className={styles.langHamSection}>
        <LangButton />

        <button className={styles.humbergerButton}>
          <IconHamburger />
        </button>
      </div>
    </header>
  )
}

export default Header
