import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import api from "../../../api/api"

const initialState: any = {
  data: [],
  isLoading: false,
  error: "",
  event_id: "",
  hash: "",
}

export const sendTestInvitation = createAsyncThunk(
  "requests/sendTestInvitation",
  async (params: any, { rejectWithValue }) => {
    try {
      const res = await api.requests.sendTestInvitation(params)
      return res.data
    } catch (error: any) {
      return rejectWithValue(error.response.data.errors)
    }
  },
)

// slice

const allSlice = createSlice({
  name: "all",
  initialState,
  reducers: {
    setLoading: (state: any) => {
      state.isLoading = true
    },
  },

  extraReducers: (builder) => {
    builder.addCase(sendTestInvitation.pending, (state, action) => {
      state.isLoading = true
      state.error = ""
    })

    builder.addCase(sendTestInvitation.fulfilled, (state, action) => {
      state.isLoading = true
      state.event_id = action.payload.event_contact_id
      state.hash = action.payload.hash
      state.isLoading = false
      state.error = ""
    })

    builder.addCase(sendTestInvitation.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
  },
})

export const { setLoading } = allSlice.actions

export default allSlice.reducer
