import React from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { LANGUAGES } from "../../constants"
import styles from "./LangButton.module.scss"

const LangButton = () => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value
    i18n.changeLanguage(lang_code)
    if (location.search.includes('?lan=')) {
      navigate(`${location.pathname}?lan=${lang_code}`)
    }
  }

  return (
    <select
      defaultValue={i18n.language}
      onChange={onChangeLang}
      className={`${styles.langButton} lang-button`}>
      {LANGUAGES.map(({ code, label }) => (
        <option value={code} key={code}>
          {label}
        </option>
      ))}
    </select>
  )
}

export default LangButton
