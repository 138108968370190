import { useTranslation } from "react-i18next"
import Logo from "../Logo"
import styles from "./Footer.module.scss"
import {
  IconGoogle,
  IconInstagram,
  IconWhatsapp2,
} from "../utils/Icons/CustomIcons"

const Footer = () => {
  const { t } = useTranslation()
  return (
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.footerLeft}>
          <Logo fillColor="#fff" />

          <p>{t("footer.address")}</p>
        </div>

        <div className={styles.footerRight}>
          <p>{t("footer.contact")}</p>

          <div className={styles.contactGroup}>
            <a
              className={styles.iconBtn}
              href="https://www.instagram.com/mazoomsa/"
              target="_blank"
            >
              <IconInstagram />
            </a>
            <a
              className={styles.iconBtn}
              target="_self"
              href="mailto:admin@mazoom.sa"
            >
              <IconGoogle />
            </a>
            <a
              className={styles.iconBtn}
              target="_blank"
              href="https://api.whatsapp.com/send?phone=966505278757&text=%D8%A3%D8%B1%D8%BA%D8%A8%20%D8%A8%D8%A7%D9%84%D8%A7%D8%B3%D8%AA%D9%81%D8%B3%D8%A7%D8%B1%20%D8%B9%D9%86%20%D8%AF%D8%B9%D9%88%D8%A7%D8%AA%20%D9%85%D8%B9%D8%B2%D9%88%D9%85"
            >
              <IconWhatsapp2 />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
