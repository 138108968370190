import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import socketio from "socket.io-client"
import Echo from "laravel-echo"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../redux/store"
import {
  IconConfirm,
  IconFailed,
  IconRecieved,
  IconReject,
  IconScanned,
  IconSending,
  IconSendingBg,
  IconSent,
  IconWaiting,
} from "../../components/utils/Icons/CustomIcons"

const TestInvitationStatusPage = () => {
  const { t } = useTranslation()
  const [status, setStatus] = useState(7)
  const [statusWA, setStatusWA] = useState(0)
  const navigate = useNavigate()

  const { hash, event_id } = useAppSelector((state) => state.all)

  useEffect(() => {
    const echo = new Echo({
      host: "https://api.mazoom.sa:6003",
      broadcaster: "socket.io",
      client: socketio,
      encrypted: false,
      transports: ["websocket"],
    })
    if (event_id !== "") {
      echo
        .channel(`test-invitation-status.${event_id}.${hash}`)
        .listen(".test-invitation-status.changed", (ev: any) => {
          setStatus(ev.eventContact.status)
          setStatusWA(ev.eventContact.whatsapp_invitation_status)
        })
    }

    return () => {
      if (event_id !== "") {
        echo.leaveChannel(`test-invitation-status.${event_id}.${hash}`)
      }
    }
  }, [event_id])

  useEffect(() => {
    event_id === "" && navigate('/')
  }, [event_id])

  const invitationStatuses = [
    { text: `${t("statuses.waiting")}`, image: <IconWaiting />, id: 0 },
    { text: `${t("statuses.confirmed")}`, image: <IconConfirm />, id: 1 },
    { text: `${t("statuses.rejected")}`, image: <IconReject />, id: 2 },
    { text: `${t("statuses.scanned")}`, image: <IconScanned />, id: 4 },
    { text: `${t("statuses.failed")}`, image: <IconFailed />, id: 6 },
    { text: `${t("statuses.sending")}`, image: <IconSendingBg />, id: 7 },
  ]
 
  const messageStatuses = [
    { text: `${t("statuses.sending")}`, image: <IconSending />, id: 0 },
    { text: `${t("statuses.sent")}`, image: <IconSent />, id: 1 },
    { text: `${t("statuses.delivered")}`, image: <IconSent />, id: 2 },
    { text: `${t("statuses.recieved")}`, image: <IconRecieved color="#B3B2AD" />, id: 3 },
    { text: `${t("statuses.read")}`, image: <IconRecieved color="#44BAF2" />, id: 4 },
    { text: `${t("statuses.failed")}`, image: <IconFailed />, id: 5 },
  ]

  return (
    <>
      <div className="test-status__container">
        <div className="test-container__text">
          <h2 className="test-container__title">{t("test.status")}</h2>
        </div>
        <div className="test-wrapper">
          <div className="test-statuses__container">
            <div className="test-statuses">
              <div className="test-statuses__wa">
                <span className="test-status">{t("test.statusWA")}</span>
                <div className="test-right">
                  <div className="test-right__status">
                    <span className="test-right__text">
                      {messageStatuses[statusWA].text}
                    </span>
                    {messageStatuses[statusWA].image}
                  </div>
                </div>
              </div>
              <div className="test-statuses__invite">
                <span className="test-status">{t("test.status")}</span>
                <div className="test-right">
                  <div className="test-right__status">
                    <span className="test-right__text">
                      {
                        invitationStatuses[
                          status > 3 ? (status > 4 ? status - 2 : 3) : status
                        ].text
                      }
                    </span>
                    {
                      invitationStatuses[
                        status > 3 ? (status > 4 ? status - 2 : 3) : status
                      ].image
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestInvitationStatusPage
