interface IProps {
  type: string
  value: string
  name: string
  className: string | null | undefined
  label: string
  onHandleChange: (arg: string) => void
  error: string
  disabled: boolean
}

const TestField = ({
  type,
  value,
  onHandleChange,
  className = "",
  error = "",
  name = "",
  label = "",
  disabled,
}: IProps) => {
  
  const errorPrint = () => {
    return <span className="input-item__error error mt-1">{error}</span>
  }

  return (
    <div className="test-input input-item">
      <input
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        className={""}
        onChange={(event) => {
          onHandleChange(event.target.value)
        }}
      />

      <label htmlFor="">{label}</label>

      {Array.isArray(error) && errorPrint()}
    </div>
  )
}

export default TestField
