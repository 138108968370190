import axios from "axios"
import endpoints from "./endpoints"

export const axiosInstance = axios.create({
  baseURL: "https://api.mazoom.sa/api",
})

axiosInstance.interceptors.request.use(async (config: any) => {
  if (config.url && config.url === endpoints.TEST) {
    config.headers = {
      ...config.headers,
    }
  }
  return config
})
