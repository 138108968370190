interface LogoProps {
  fillColor: string
}

const Logo = ({ fillColor }: LogoProps) => {
  return (
    <svg
      width="126"
      height="40"
      viewBox="0 0 126 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M53.5955 1.06897L48.8196 8.58918L44.0576 1.06897H42.906V13.1423H44.1634V3.46866L48.3879 10.1256H49.2325L53.4925 3.46866V13.1423H54.7518V1.06897H53.5955Z"
        fill={fillColor}
      />
      <path
        d="M66.3861 13.1423H67.7643L63.1952 1.06897H62.0558L57.5017 13.1423H58.8837L60.1935 9.64249H65.0753L66.3861 13.1423ZM60.657 8.39911L62.6241 3.05201L64.6071 8.39817L60.657 8.39911Z"
        fill={fillColor}
      />
      <path
        d="M71.4307 11.8839L78.8985 2.31329V1.06897H70.2604V2.31329H77.3115L69.7407 11.8839V13.1423H79.141V11.8839H71.4307Z"
        fill={fillColor}
      />
      <path
        d="M87.2408 0.966919C83.8468 0.966919 81.3778 3.72426 81.3778 7.12203C81.3778 10.5207 83.8468 13.2809 87.2408 13.2809C90.6386 13.2809 93.0888 10.5198 93.0888 7.12203C93.0888 3.72426 90.6386 0.966919 87.2408 0.966919ZM87.2408 12.0562C84.5162 12.0562 82.654 9.8466 82.654 7.12203C82.654 4.39745 84.5162 2.19251 87.2408 2.19251C89.9504 2.19251 91.8127 4.39745 91.8127 7.12203C91.8127 9.8466 89.9504 12.0562 87.2408 12.0562Z"
        fill={fillColor}
      />
      <path
        d="M101.616 0.966919C98.2224 0.966919 95.7534 3.72426 95.7534 7.12203C95.7534 10.5207 98.2224 13.2809 101.616 13.2809C105.014 13.2809 107.464 10.5198 107.464 7.12203C107.464 3.72426 105.014 0.966919 101.616 0.966919ZM101.616 12.0562C98.8918 12.0562 97.0296 9.8466 97.0296 7.12203C97.0296 4.39745 98.8918 2.19251 101.616 2.19251C104.326 2.19251 106.188 4.39745 106.188 7.12203C106.188 9.8466 104.326 12.0562 101.616 12.0562Z"
        fill={fillColor}
      />
      <path
        d="M121.168 1.06897L116.392 8.58918L111.63 1.06897H110.478V13.1423H111.736V3.46866L115.96 10.1256H116.805L121.065 3.46866V13.1423H122.324V1.06897H121.168Z"
        fill={fillColor}
      />
      <path
        d="M41.6505 32.8079C41.1018 32.2443 40.8312 31.4616 40.8312 30.4672C40.8312 29.3072 41.1711 28.3887 41.8546 27.7164C42.4697 27.1125 43.2777 26.8017 44.2833 26.7802C45.1213 26.7802 45.7935 26.9918 46.3019 27.4093C46.8871 27.899 47.1802 28.5937 47.1802 29.501V32.4409C47.1802 32.8435 47.0378 33.1843 46.756 33.4661C46.6373 33.5961 46.4926 33.6997 46.3314 33.7702C46.1701 33.8407 45.9958 33.8765 45.8197 33.8753H41.5035C41.1234 33.8753 40.9333 34.0653 40.9333 34.4464V38.9658L40.2891 39.493H39.6609V34.2264C39.6609 33.8463 39.792 33.5167 40.0551 33.2311C40.3266 32.9502 40.6477 32.807 41.0213 32.807L41.6505 32.8079ZM45.8769 29.5C45.8769 29.0544 45.7495 28.6733 45.4967 28.3587C45.1934 28.0189 44.7795 27.8475 44.2533 27.8475C43.6167 27.8475 43.1017 28.0778 42.7029 28.5347C42.3115 28.9954 42.1177 29.5881 42.1177 30.3193C42.1177 31.102 42.3115 31.7237 42.7029 32.1919C43.054 32.6019 43.4818 32.8061 43.9902 32.8061H45.3207C45.6905 32.8061 45.8769 32.616 45.8769 32.2359V29.5Z"
        fill={fillColor}
      />
      <path
        d="M53.8895 33.7293C53.3575 33.9139 52.7982 34.0076 52.2351 34.0064C51.1527 34.0064 50.2932 33.6253 49.6603 32.8641C49.1051 32.1919 48.827 31.3361 48.827 30.2894C48.827 29.237 49.1191 28.3953 49.7043 27.7595C50.3372 27.0976 51.1968 26.7643 52.2791 26.7643C53.0871 26.7643 53.7462 26.9693 54.2546 27.3794C54.8688 27.8756 55.1759 28.5863 55.1759 29.5001V33.7443C55.1656 35.0307 54.7443 36.0007 53.9185 36.6561C53.4972 36.9847 52.9897 37.215 52.3971 37.3433C51.9924 37.422 51.581 37.4612 51.1687 37.4604C50.9346 37.4604 50.7043 37.4529 50.4805 37.4463L49.9833 36.8312V36.349C50.2829 36.3855 50.6013 36.407 50.9346 36.407C52.8942 36.407 53.8783 35.5148 53.8895 33.7293ZM53.8895 29.501C53.8895 28.8793 53.6629 28.4149 53.2163 28.1116C52.971 27.9356 52.6602 27.8476 52.28 27.8476C51.6068 27.8476 51.0732 28.0788 50.6855 28.5357C50.2942 28.9851 50.1004 29.6106 50.1004 30.4083C50.1004 31.1685 50.3232 31.7753 50.7736 32.2219C51.1612 32.632 51.6836 32.837 52.339 32.837C52.9017 32.837 53.4214 32.7452 53.8895 32.5589V29.501Z"
        fill={fillColor}
      />
      <path
        d="M60.377 23.6342C60.5708 23.6342 60.7422 23.7082 60.8892 23.8542C60.9601 23.9191 61.0163 23.9983 61.0541 24.0866C61.0919 24.175 61.1103 24.2703 61.1083 24.3664C61.1083 24.5817 61.0352 24.7615 60.8892 24.9076C60.8232 24.977 60.7437 25.0322 60.6557 25.0699C60.5676 25.1076 60.4728 25.1269 60.377 25.1267C60.2789 25.1281 60.1814 25.1094 60.0908 25.0717C60.0001 25.0341 59.9181 24.9782 59.8499 24.9076C59.7783 24.8372 59.7218 24.7528 59.6842 24.6597C59.6465 24.5666 59.6283 24.4668 59.6308 24.3664C59.6287 24.2703 59.6472 24.175 59.685 24.0866C59.7228 23.9983 59.779 23.9191 59.8499 23.8542C59.918 23.7835 60 23.7274 60.0906 23.6896C60.1813 23.6517 60.2788 23.6329 60.377 23.6342ZM61.0062 26.9412V32.807H61.7665V33.8753H61.0062C60.9762 34.9173 60.6588 35.7516 60.0549 36.377C59.5653 36.8742 58.8921 37.2037 58.0363 37.3713C57.6982 37.4315 57.3554 37.4609 57.012 37.4593C56.7667 37.4593 56.5364 37.4518 56.3248 37.4453L55.8276 36.8302V36.348C56.1198 36.3845 56.4343 36.406 56.778 36.406C58.7385 36.406 59.7179 35.5025 59.7179 33.7002V26.9402L61.0062 26.9412Z"
        fill={fillColor}
      />
      <path
        d="M63.756 29.3989C64.0931 28.5295 64.7175 27.8016 65.5256 27.3363C66.1885 26.9552 66.8757 26.7651 67.5892 26.7651C68.3106 26.7682 69.0179 26.9654 69.6368 27.3363C70.442 27.8012 71.0621 28.5298 71.3923 29.3989L71.2613 29.764C70.5656 30.0571 70.0029 30.4522 69.5638 30.9494C69.1056 31.4741 68.8062 32.1184 68.7005 32.807H71.5534V33.8753H68.4814C68.1884 33.8753 67.9 33.7292 67.6182 33.4361H67.5742C67.2999 33.7292 67.0077 33.8753 66.696 33.8753H61.6635V32.8079H66.4619C66.346 32.1792 66.0914 31.5843 65.7166 31.0664C65.2672 30.5103 64.6633 30.0786 63.9021 29.765L63.756 29.3989ZM70.0029 29.2519C69.6808 28.766 69.3044 28.4036 68.8766 28.1696C68.4805 27.9579 68.0382 27.8475 67.5892 27.8484C67.121 27.8484 66.6997 27.9467 66.3308 28.1405C65.8917 28.3559 65.5003 28.7257 65.1605 29.2528C65.6754 29.4794 66.1258 29.8052 66.5059 30.2322C66.9637 30.7518 67.3148 31.4025 67.5592 32.1928H67.6032C67.8448 31.3735 68.2071 30.7078 68.6865 30.1891C69.055 29.7885 69.5035 29.4695 70.0029 29.2528V29.2519Z"
        fill={fillColor}
      />
      <path
        d="M74.1404 29.501C74.1404 28.4767 74.499 27.7277 75.2227 27.2483C75.6983 26.9374 76.3275 26.7802 77.1102 26.7802C78.0652 26.7802 78.8658 27.1088 79.5099 27.7604C80.1532 28.4149 80.4743 29.3259 80.4743 30.4962C80.4743 31.6011 80.1532 32.5261 79.5099 33.2751C78.9013 33.9783 78.1383 34.3284 77.2114 34.3284C76.3519 34.3284 75.6496 33.9998 75.1047 33.3491C74.9798 33.517 74.8163 33.6524 74.6281 33.744C74.44 33.8355 74.2325 33.8806 74.0233 33.8753H71.4345V32.8079H73.5692C73.6451 32.8108 73.7207 32.7979 73.7913 32.77C73.8619 32.7421 73.9259 32.6999 73.9793 32.6459C74.0837 32.5354 74.1414 32.3888 74.1404 32.2368V29.501ZM75.4277 32.4559C75.9427 32.9942 76.5176 33.2611 77.1542 33.2611C77.7572 33.2611 78.2441 33.012 78.6167 32.5149C78.9978 32.0177 79.1878 31.3698 79.1878 30.5693C79.1878 29.691 78.9678 28.9963 78.5296 28.4767C78.1561 28.06 77.6664 27.8484 77.0513 27.8484C76.5906 27.8484 76.218 27.987 75.9249 28.2576C75.5925 28.5722 75.4287 28.9851 75.4287 29.501L75.4277 32.4559Z"
        fill={fillColor}
      />
      <path
        d="M90.2622 26.5311C90.358 26.5309 90.4529 26.5503 90.541 26.5881C90.6291 26.6259 90.7085 26.6814 90.7743 26.7511C90.8456 26.8217 90.9018 26.9061 90.9395 26.9992C90.9772 27.0922 90.9955 27.1919 90.9934 27.2923C90.9934 27.4889 90.9204 27.6574 90.7743 27.8044C90.7083 27.8739 90.6289 27.9291 90.5408 27.9668C90.4528 28.0044 90.3579 28.0238 90.2622 28.0235C90.1618 28.026 90.0619 28.0079 89.9688 27.9702C89.8757 27.9325 89.7914 27.8761 89.721 27.8044C89.6545 27.737 89.602 27.657 89.5668 27.569C89.5316 27.4811 89.5143 27.387 89.516 27.2923C89.516 27.0797 89.5815 26.8972 89.721 26.7511C89.7913 26.6793 89.8756 26.6227 89.9687 26.5849C90.0618 26.547 90.1617 26.5287 90.2622 26.5311ZM92.0767 26.5311C92.1771 26.5291 92.2769 26.5476 92.3699 26.5854C92.4629 26.6232 92.5473 26.6796 92.6179 26.7511C92.6892 26.8217 92.7454 26.9061 92.7831 26.9992C92.8207 27.0922 92.8391 27.1919 92.8369 27.2923C92.8369 27.4889 92.7639 27.6574 92.6179 27.8044C92.5471 27.8759 92.4626 27.9321 92.3694 27.9698C92.2762 28.0075 92.1763 28.0258 92.0757 28.0235C91.9801 28.024 91.8853 28.0048 91.7974 27.9671C91.7094 27.9294 91.6302 27.8741 91.5645 27.8044C91.4946 27.7388 91.439 27.6594 91.4011 27.5713C91.3633 27.4832 91.344 27.3882 91.3445 27.2923C91.3425 27.1919 91.361 27.0921 91.3988 26.9991C91.4367 26.906 91.493 26.8217 91.5645 26.7511C91.6301 26.6813 91.7093 26.6258 91.7972 26.5879C91.8852 26.5501 91.98 26.5307 92.0757 26.5311H92.0767ZM97.3283 26.9412V32.4418C97.3283 32.8032 97.215 33.1141 96.9912 33.3781C96.7066 33.7105 96.3667 33.8753 95.9679 33.8753H87.3503C86.793 33.8864 86.2529 33.6818 85.8429 33.3041C85.2652 32.8079 84.9797 32.1712 84.9797 31.3885V28.5506L85.6238 28.0235H86.2671V31.3885C86.2671 31.7799 86.4028 32.1123 86.6772 32.3828C86.9477 32.6684 87.2914 32.807 87.7014 32.807H95.4688C95.8499 32.807 96.04 32.6169 96.04 32.2368V26.9412H97.3283Z"
        fill={fillColor}
      />
      <path
        d="M100.674 23.0931V33.7293L100.015 34.2555H99.3871V23.0931H100.674Z"
        fill={fillColor}
      />
      <path
        d="M105.934 33.8753C104.791 33.8753 103.914 33.4951 103.3 32.7339C102.791 32.101 102.54 31.2855 102.54 30.2902C102.54 29.1498 102.879 28.2679 103.563 27.6434C104.178 27.0582 104.986 26.7651 105.992 26.7651C106.771 26.7651 107.419 26.9627 107.937 27.3503C108.57 27.8409 108.889 28.5581 108.889 29.5009V32.807H109.372V33.8753H108.889C108.848 34.9286 108.53 35.7553 107.937 36.362C107.488 36.8199 106.903 37.1373 106.182 37.3133C105.755 37.4114 105.318 37.4604 104.88 37.4593C104.646 37.4593 104.416 37.4518 104.192 37.4453L103.695 36.8302V36.348C103.995 36.3845 104.313 36.406 104.646 36.406C106.566 36.406 107.55 35.5615 107.601 33.8753H105.934ZM107.6 29.5009C107.6 28.9963 107.448 28.5899 107.147 28.2866C106.855 27.9945 106.467 27.8484 105.992 27.8484C105.318 27.8484 104.785 28.0787 104.397 28.5356C104.006 28.9851 103.812 29.6105 103.812 30.4082C103.812 31.1216 104.006 31.7031 104.397 32.1497C104.796 32.5879 105.304 32.807 105.919 32.807H107.601L107.6 29.5009Z"
        fill={fillColor}
      />
      <path
        d="M112.54 32.8079C111.885 32.1338 111.56 31.2715 111.56 30.2181C111.56 29.088 111.933 28.1995 112.686 27.5554C113.308 27.0282 114.113 26.7651 115.101 26.7651C115.627 26.7651 116.183 26.8494 116.767 27.0142V28.0235C116.172 27.8774 115.656 27.8044 115.218 27.8044C114.475 27.8044 113.893 28.0347 113.476 28.4916C113.055 28.9523 112.847 29.5815 112.847 30.3792C112.847 31.0336 113.067 31.6076 113.505 32.1047C113.923 32.5729 114.401 32.807 114.94 32.807H117.983V33.232L117.455 33.8753H109.249V32.8079H112.54Z"
        fill={fillColor}
      />
      <path
        d="M120.717 26.8531C121.075 26.7942 121.466 26.7651 121.887 26.7651C122.999 26.7651 123.895 27.1022 124.579 27.7744C125.193 28.3999 125.5 29.1901 125.5 30.1451V32.4418C125.509 32.8103 125.372 33.1674 125.12 33.4361C125.001 33.5756 124.853 33.6873 124.686 33.7631C124.519 33.839 124.338 33.8773 124.155 33.8753H119.195V32.8079H123.657C124.026 32.8079 124.213 32.6169 124.213 32.2368V30.3202C124.213 29.6105 123.997 29.015 123.569 28.5356C123.101 28.06 122.388 27.8147 121.434 27.8044C121.193 27.8016 120.953 27.8211 120.717 27.8625V26.8531Z"
        fill={fillColor}
      />
      <path
        d="M26.5763 3.29919L16.3578 19.6654L6.23472 3.29919H0.5V35.6627H6.65136V15.2733L14.5554 28.0339H18.2088L26.1157 15.2724V35.6627H32.2642V3.29919H26.5763Z"
        fill={fillColor}
      />
    </svg>
  )
}

export default Logo
