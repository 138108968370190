import Footer from "@/components/Footer"
import Header from "@/components/Header"
import MainContent from "@/components/MainContent"
import Preloader from "@/components/utils/Preloader/Preloader"
import { useAppSelector } from "@/redux/store"
import { Outlet } from "react-router-dom"


const PageWrapper = () => {
  const { error, hash, event_id, isLoading } = useAppSelector((state) => state.all)
  return (
    <div className="main">
      {isLoading && <Preloader />}
      <Header />
      <MainContent>
        <Outlet />
      </MainContent>
      <Footer />
    </div>
  )
}

export default PageWrapper
