export const IconConfirm = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#46BA65"
      />
      <path
        d="M10.3943 17.824C10.2634 17.9695 10.0848 18.0506 9.89925 18.0506C9.71372 18.0506 9.53511 17.9695 9.4042 17.824L5.45058 13.4553C5.04028 13.002 5.04028 12.267 5.45058 11.8145L5.94563 11.2675C6.35606 10.8142 7.02062 10.8142 7.43092 11.2675L9.89925 13.9946L16.5691 6.62569C16.9795 6.17239 17.6447 6.17239 18.0543 6.62569L18.5494 7.17276C18.9597 7.62606 18.9597 8.36097 18.5494 8.81356L10.3943 17.824Z"
        fill="white"
      />
    </svg>
  )
}

export const IconWaiting = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#F9B819"
      />
      <path
        d="M16.1905 8.24306V7.14001C16.5021 6.95834 16.7143 6.62422 16.7143 6.23809V5.9762C16.7143 5.83143 16.5971 5.71429 16.4524 5.71429H7.54763C7.40286 5.71429 7.28571 5.83143 7.28571 5.9762V6.23811C7.28571 6.62422 7.49791 6.95834 7.80952 7.14004V8.24306C7.80952 9.28096 8.252 10.2744 9.0234 10.9685L10.1695 12L9.0234 13.0315C8.252 13.7257 7.80952 14.719 7.80952 15.7569V16.86C7.49791 17.0417 7.28571 17.3758 7.28571 17.7619V18.0238C7.28571 18.1686 7.40286 18.2857 7.54763 18.2857H16.4524C16.5972 18.2857 16.7143 18.1686 16.7143 18.0238V17.7619C16.7143 17.3758 16.5021 17.0417 16.1905 16.86V15.7569C16.1905 14.719 15.748 13.7257 14.9766 13.0315L13.8305 12L14.9766 10.9685C15.748 10.2744 16.1905 9.28094 16.1905 8.24306ZM14.2758 10.1899L12.6972 11.6107C12.5867 11.71 12.5238 11.8514 12.5238 12C12.5238 12.1486 12.5867 12.2901 12.6972 12.3893L14.2758 13.8101C14.8267 14.306 15.1429 15.0157 15.1429 15.7569V16.7143H14.488L12.2095 13.6763C12.1107 13.5443 11.8892 13.5443 11.7905 13.6763L9.51204 16.7143H8.85714V15.7569C8.85714 15.0157 9.17327 14.306 9.7242 13.81L11.3028 12.3892C11.4133 12.29 11.4762 12.1486 11.4762 12C11.4762 11.8514 11.4133 11.7099 11.3028 11.6107L9.7242 10.1899C9.17327 9.694 8.85714 8.98426 8.85714 8.24306V7.28571H15.1429V8.24306C15.1429 8.98426 14.8267 9.694 14.2758 10.1899Z"
        fill="white"
      />
      <path
        d="M13.8051 9.38094H10.1945C10.0909 9.38094 9.99701 9.44208 9.95507 9.53671C9.91313 9.63161 9.93076 9.7421 10.0006 9.81883L11.8242 11.5013C11.8741 11.5465 11.937 11.569 11.9999 11.569C12.0628 11.569 12.1257 11.5465 12.1756 11.5013L13.999 9.81883C14.0688 9.7421 14.0864 9.63161 14.0445 9.53671C14.0026 9.44208 13.9087 9.38094 13.8051 9.38094Z"
        fill="white"
      />
    </svg>
  )
}

export const IconSent = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.40594 17.8891C8.40594 17.7521 8.39071 17.6912 8.3298 17.6912L7.97957 17.8587C7.97957 17.7825 7.93389 17.7369 7.85775 17.7064L7.73593 17.6912C7.62934 17.6912 7.58365 17.7064 7.43138 17.7978C7.3857 17.7064 7.32479 17.5998 7.27911 17.5084C6.88319 16.7471 6.47205 15.605 6.28932 15.1025C6.19796 14.8436 6.10659 14.3107 6 13.5036C6.12182 13.5798 6.21318 13.6102 6.25887 13.6102C6.31978 13.6102 6.39591 13.5036 6.45682 13.2904C6.48728 13.3361 6.54819 13.3514 6.62433 13.3514C6.67001 13.3514 6.73092 13.3361 6.76137 13.2904L7.00501 12.925L7.27911 13.0164H7.29433C7.32479 13.0164 7.37047 12.9707 7.44661 12.925C7.52274 12.8793 7.58365 12.8488 7.62934 12.8488L7.67502 12.8641C7.91866 12.9859 8.08616 13.1991 8.1623 13.5341C8.34503 14.3107 8.51253 14.6914 8.71049 14.6914C8.87799 14.6914 9.1064 14.4934 9.35004 14.1127C9.59368 13.732 9.83732 13.2143 10.1114 12.59C10.1266 12.7118 10.1419 12.7727 10.1723 12.7727C10.2637 12.7727 10.4921 12.2398 11.0555 11.3413C11.893 9.98609 13.964 7.3822 14.4969 7.01674C14.8928 6.74264 15.1974 6.48378 15.4106 6.25537C15.3801 6.40764 15.3497 6.51423 15.3497 6.55991C15.3497 6.6056 15.3801 6.62082 15.4106 6.62082L15.8369 6.40764V6.46855C15.8369 6.54469 15.8522 6.59037 15.8978 6.59037C15.9587 6.59037 16.2024 6.34673 16.2328 6.25537L16.2024 6.46855L16.7201 6.164L16.5983 6.4381C16.7506 6.3315 16.8724 6.27059 16.9485 6.27059C17.0247 6.27059 17.0704 6.39241 17.0704 6.46855C17.0704 6.59037 16.9638 6.75787 16.7963 6.97106C16.6135 7.2147 16.1567 7.68675 14.7862 9.25517C14.1924 9.92518 11.558 13.5036 11.0555 14.3564L10.1114 15.9552C9.70027 16.6405 9.4414 17.0821 9.30436 17.2496C9.16731 17.4171 8.99981 17.5846 8.80185 17.7369L8.6648 17.6607L8.54298 17.7369L8.40594 17.8891Z"
        fill="#B3B2AD"
      />
    </svg>
  )
}

export const IconScanned = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z"
        fill="#EC7A30"
      />
      <path
        d="M9.66667 7.28571H8.71429C7.92667 7.28571 7.28571 7.92667 7.28571 8.71429V9.66667C7.28571 9.93 7.49905 10.1429 7.7619 10.1429C8.02476 10.1429 8.2381 9.93 8.2381 9.66667V8.71429C8.2381 8.45143 8.45191 8.2381 8.71429 8.2381H9.66667C9.92952 8.2381 10.1429 8.02524 10.1429 7.7619C10.1429 7.49857 9.92952 7.28571 9.66667 7.28571Z"
        fill="white"
      />
      <path
        d="M9.66667 17.7619H8.71429C8.45191 17.7619 8.2381 17.5486 8.2381 17.2857V16.3333C8.2381 16.07 8.02476 15.8571 7.7619 15.8571C7.49905 15.8571 7.28571 16.07 7.28571 16.3333V17.2857C7.28571 18.0733 7.92667 18.7143 8.71429 18.7143H9.66667C9.92952 18.7143 10.1429 18.5014 10.1429 18.2381C10.1429 17.9748 9.92952 17.7619 9.66667 17.7619Z"
        fill="white"
      />
      <path
        d="M17.2857 7.28571H16.3333C16.0705 7.28571 15.8571 7.49857 15.8571 7.7619C15.8571 8.02524 16.0705 8.2381 16.3333 8.2381H17.2857C17.5481 8.2381 17.7619 8.45143 17.7619 8.71429V9.66667C17.7619 9.93 17.9752 10.1429 18.2381 10.1429C18.501 10.1429 18.7143 9.93 18.7143 9.66667V8.71429C18.7143 7.92667 18.0733 7.28571 17.2857 7.28571Z"
        fill="white"
      />
      <path
        d="M18.2381 15.8571C17.9752 15.8571 17.7619 16.07 17.7619 16.3333V17.2857C17.7619 17.5486 17.5481 17.7619 17.2857 17.7619H16.3333C16.0705 17.7619 15.8571 17.9748 15.8571 18.2381C15.8571 18.5014 16.0705 18.7143 16.3333 18.7143H17.2857C18.0733 18.7143 18.7143 18.0733 18.7143 17.2857V16.3333C18.7143 16.07 18.501 15.8571 18.2381 15.8571Z"
        fill="white"
      />
      <path
        d="M18.2381 13.2666H7.7619C7.49905 13.2666 7.28571 13.0537 7.28571 12.7904C7.28571 12.527 7.49905 12.3142 7.7619 12.3142H18.2381C18.501 12.3142 18.7143 12.527 18.7143 12.7904C18.7143 13.0537 18.501 13.2666 18.2381 13.2666Z"
        fill="white"
      />
      <path
        d="M13 25.25C19.7655 25.25 25.25 19.7655 25.25 13C25.25 6.23451 19.7655 0.75 13 0.75C6.23451 0.75 0.75 6.23451 0.75 13C0.75 19.7655 6.23451 25.25 13 25.25Z"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  )
}

export const IconReject = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#DADADA"
      />
      <path
        d="M13.8857 12.0004L17.4213 8.46478C17.8119 8.07413 17.8119 7.4408 17.4213 7.05077L16.9499 6.57943C16.5592 6.18866 15.9258 6.18866 15.5358 6.57943L12.0004 10.1149L8.46478 6.5787C8.07413 6.18805 7.4408 6.18805 7.05077 6.5787L6.5787 7.05003C6.18805 7.4408 6.18805 8.07413 6.5787 8.46417L10.1149 12.0004L6.57943 15.5358C6.18866 15.9266 6.18866 16.5599 6.57943 16.9499L7.05077 17.4213C7.44141 17.8119 8.07474 17.8119 8.46478 17.4213L12.0004 13.8857L15.5358 17.4213C15.9266 17.8119 16.5599 17.8119 16.9499 17.4213L17.4213 16.9499C17.8119 16.5592 17.8119 15.9258 17.4213 15.5358L13.8857 12.0004Z"
        fill="white"
      />
    </svg>
  )
}

export const IconRecieved = ({ color }: { color: string }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.40594 17.8891C2.40594 17.7521 2.39071 17.6912 2.3298 17.6912L1.97957 17.8587C1.97957 17.7825 1.93389 17.7369 1.85775 17.7064L1.73593 17.6912C1.62934 17.6912 1.58365 17.7064 1.43138 17.7978C1.3857 17.7064 1.32479 17.5998 1.27911 17.5084C0.883192 16.7471 0.472051 15.605 0.289321 15.1025C0.197957 14.8436 0.106592 14.3107 0 13.5036C0.12182 13.5798 0.213184 13.6102 0.258867 13.6102C0.319776 13.6102 0.395914 13.5036 0.456823 13.2904C0.487278 13.3361 0.548188 13.3514 0.624325 13.3514C0.670008 13.3514 0.730917 13.3361 0.761372 13.2904L1.00501 12.925L1.27911 13.0164H1.29433C1.32479 13.0164 1.37047 12.9707 1.44661 12.925C1.52274 12.8793 1.58365 12.8488 1.62934 12.8488L1.67502 12.8641C1.91866 12.9859 2.08616 13.1991 2.1623 13.5341C2.34503 14.3107 2.51253 14.6914 2.71049 14.6914C2.87799 14.6914 3.1064 14.4934 3.35004 14.1127C3.59368 13.732 3.83732 13.2143 4.11141 12.59C4.12664 12.7118 4.14187 12.7727 4.17232 12.7727C4.26368 12.7727 4.4921 12.2398 5.05551 11.3413C5.89302 9.98609 7.96395 7.3822 8.49691 7.01674C8.89283 6.74264 9.19738 6.48378 9.41056 6.25537C9.38011 6.40764 9.34965 6.51423 9.34965 6.55991C9.34965 6.6056 9.38011 6.62082 9.41056 6.62082L9.83693 6.40764V6.46855C9.83693 6.54469 9.85216 6.59037 9.89784 6.59037C9.95875 6.59037 10.2024 6.34673 10.2328 6.25537L10.2024 6.46855L10.7201 6.164L10.5983 6.4381C10.7506 6.3315 10.8724 6.27059 10.9485 6.27059C11.0247 6.27059 11.0704 6.39241 11.0704 6.46855C11.0704 6.59037 10.9638 6.75787 10.7963 6.97106C10.6135 7.2147 10.1567 7.68675 8.78624 9.25517C8.19237 9.92518 5.55802 13.5036 5.05551 14.3564L4.11141 15.9552C3.70027 16.6405 3.4414 17.0821 3.30436 17.2496C3.16731 17.4171 2.99981 17.5846 2.80185 17.7369L2.6648 17.6607L2.54298 17.7369L2.40594 17.8891Z"
        fill={color ? color : "#B3B2AD"}
      />
      <path
        d="M15.3356 17.8891C15.3356 17.7521 15.3204 17.6912 15.2594 17.6912L14.9092 17.8587C14.9092 17.7825 14.8635 17.7369 14.7874 17.7064L14.6656 17.6912C14.559 17.6912 14.5133 17.7064 14.361 17.7978C14.3153 17.7064 14.2544 17.5998 14.2088 17.5084C13.8128 16.7471 13.4017 15.605 13.219 15.1025C13.1276 14.8436 13.0362 14.3107 12.9296 13.5036C13.0515 13.5798 13.1428 13.6102 13.1885 13.6102C13.2494 13.6102 13.3256 13.5036 13.3865 13.2904C13.4169 13.3361 13.4778 13.3514 13.554 13.3514C13.5997 13.3514 13.6606 13.3361 13.691 13.2904L13.9347 12.925L14.2088 13.0164H14.224C14.2544 13.0164 14.3001 12.9707 14.3763 12.925C14.4524 12.8793 14.5133 12.8488 14.559 12.8488L14.6047 12.8641C14.8483 12.9859 15.0158 13.1991 15.0919 13.5341C15.2747 14.3107 15.4422 14.6914 15.6401 14.6914C15.8076 14.6914 16.036 14.4934 16.2797 14.1127C16.5233 13.732 16.767 13.2143 17.0411 12.59C17.0563 12.7118 17.0715 12.7727 17.102 12.7727C17.1933 12.7727 17.4217 12.2398 17.9852 11.3413C18.8227 9.98609 20.8936 7.3822 21.4266 7.01674C21.8225 6.74264 22.127 6.48378 22.3402 6.25537C22.3098 6.40764 22.2793 6.51423 22.2793 6.55991C22.2793 6.6056 22.3098 6.62082 22.3402 6.62082L22.7666 6.40764V6.46855C22.7666 6.54469 22.7818 6.59037 22.8275 6.59037C22.8884 6.59037 23.132 6.34673 23.1625 6.25537L23.132 6.46855L23.6498 6.164L23.5279 6.4381C23.6802 6.3315 23.802 6.27059 23.8782 6.27059C23.9543 6.27059 24 6.39241 24 6.46855C24 6.59037 23.8934 6.75787 23.7259 6.97106C23.5432 7.2147 23.0864 7.68675 21.7159 9.25517C21.122 9.92518 18.4877 13.5036 17.9852 14.3564L17.0411 15.9552C16.6299 16.6405 16.371 17.0821 16.234 17.2496C16.097 17.4171 15.9295 17.5846 15.7315 17.7369L15.5944 17.6607L15.4726 17.7369L15.3356 17.8891Z"
        fill={color ? color : "#B3B2AD"}
      />
    </svg>
  )
}

export const IconFailed = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#EB5757"
      />
      <path
        d="M13.8857 12.0004L17.4213 8.46478C17.8119 8.07413 17.8119 7.4408 17.4213 7.05077L16.9499 6.57943C16.5592 6.18866 15.9258 6.18866 15.5358 6.57943L12.0004 10.1149L8.46478 6.5787C8.07413 6.18805 7.4408 6.18805 7.05077 6.5787L6.5787 7.05003C6.18805 7.4408 6.18805 8.07413 6.5787 8.46417L10.1149 12.0004L6.57943 15.5358C6.18866 15.9266 6.18866 16.5599 6.57943 16.9499L7.05077 17.4213C7.44141 17.8119 8.07474 17.8119 8.46478 17.4213L12.0004 13.8857L15.5358 17.4213C15.9266 17.8119 16.5599 17.8119 16.9499 17.4213L17.4213 16.9499C17.8119 16.5592 17.8119 15.9258 17.4213 15.5358L13.8857 12.0004Z"
        fill="white"
      />
    </svg>
  )
}

export const IconMail = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1659_20375)">
        <path
          d="M11.9999 2.3999C9.45382 2.3999 7.01203 3.41133 5.21168 5.21168C3.41133 7.01203 2.3999 9.45382 2.3999 11.9999C2.3999 14.546 3.41133 16.9878 5.21168 18.7881C7.01203 20.5885 9.45382 21.5999 11.9999 21.5999C12.2386 21.5999 12.4675 21.5051 12.6363 21.3363C12.8051 21.1675 12.8999 20.9386 12.8999 20.6999C12.8999 20.4612 12.8051 20.2323 12.6363 20.0635C12.4675 19.8947 12.2386 19.7999 11.9999 19.7999C10.4572 19.7999 8.94916 19.3424 7.66645 18.4854C6.38375 17.6283 5.38401 16.4101 4.79364 14.9848C4.20328 13.5596 4.04881 11.9912 4.34978 10.4782C4.65074 8.96515 5.39362 7.57532 6.48447 6.48447C7.57532 5.39362 8.96515 4.65074 10.4782 4.34978C11.9912 4.04881 13.5596 4.20328 14.9848 4.79364C16.4101 5.38401 17.6283 6.38375 18.4854 7.66645C19.3424 8.94916 19.7999 10.4572 19.7999 11.9999C19.7999 14.9129 19.2059 15.1499 18.4889 15.1499C16.9889 15.1499 16.9499 12.9059 16.9499 12.8829V11.9999C16.9537 10.8352 16.5481 9.70612 15.8039 8.81011C15.0597 7.9141 14.0243 7.30812 12.8787 7.09807C11.733 6.88803 10.55 7.08728 9.53631 7.661C8.52266 8.23473 7.74288 9.14644 7.33324 10.2368C6.92361 11.3271 6.91018 12.5267 7.29531 13.626C7.68043 14.7252 8.43962 15.6542 9.44017 16.2504C10.4407 16.8467 11.619 17.0724 12.7691 16.888C13.9191 16.7037 14.9678 16.121 15.7319 15.2419C15.9811 15.7598 16.3731 16.1957 16.8617 16.4983C17.3503 16.8009 17.9152 16.9576 18.4899 16.9499C21.6009 16.9499 21.6009 13.4809 21.6009 11.9999C21.598 9.45455 20.5855 7.01429 18.7856 5.21454C16.9856 3.41479 14.5453 2.40255 11.9999 2.3999ZM11.9999 15.1499C11.3769 15.1499 10.7679 14.9652 10.2499 14.619C9.73184 14.2729 9.3281 13.7809 9.08968 13.2054C8.85127 12.6298 8.78889 11.9964 8.91043 11.3854C9.03197 10.7743 9.33198 10.2131 9.77252 9.77252C10.2131 9.33198 10.7743 9.03197 11.3854 8.91043C11.9964 8.78889 12.6298 8.85127 13.2054 9.08968C13.7809 9.3281 14.2729 9.73184 14.619 10.2499C14.9652 10.7679 15.1499 11.3769 15.1499 11.9999C15.1491 12.8351 14.817 13.6358 14.2264 14.2264C13.6358 14.817 12.8351 15.1491 11.9999 15.1499Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1659_20375">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconSendingBg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
        fill="#6FCF97"
      />
      <path
        d="M12.0312 16.8312C10.639 16.8312 9.45455 16.3481 8.47792 15.3818C7.5013 14.4156 7.01299 13.2364 7.01299 11.8442V11.7351L6.45195 12.2961C6.21095 12.5371 5.82022 12.5371 5.57922 12.2961C5.33822 12.0551 5.33822 11.6644 5.57922 11.4234L6.92926 10.0733C7.31978 9.68282 7.95295 9.68282 8.34347 10.0733L9.69351 11.4234C9.9345 11.6644 9.9345 12.0551 9.69351 12.2961C9.45251 12.5371 9.06178 12.5371 8.82078 12.2961L8.25974 11.7351V11.8442C8.25974 12.8831 8.62597 13.7662 9.35844 14.4935C10.0909 15.2208 10.9818 15.5844 12.0312 15.5844C12.3013 15.5844 12.5662 15.5532 12.826 15.4909C12.9369 15.4643 13.0469 15.432 13.1559 15.394C13.4217 15.3014 13.7238 15.3446 13.9229 15.5436C14.2266 15.8474 14.1592 16.3593 13.7578 16.5126C13.6096 16.5692 13.46 16.6182 13.3091 16.6597C12.8935 16.774 12.4675 16.8312 12.0312 16.8312ZM17.0707 13.615C16.6802 14.0055 16.0471 14.0055 15.6565 13.615L14.3065 12.2649C14.0655 12.0239 14.0655 11.6332 14.3065 11.3922C14.5475 11.1512 14.9382 11.1512 15.1792 11.3922L15.7403 11.9532V11.8442C15.7403 10.8052 15.374 9.92208 14.6416 9.19481C13.9091 8.46753 13.0182 8.1039 11.9688 8.1039C11.6987 8.1039 11.4338 8.13507 11.174 8.1974C11.0631 8.22402 10.9531 8.25633 10.8441 8.29432C10.5783 8.38693 10.2762 8.34369 10.0771 8.14467C9.77335 7.84089 9.84081 7.32897 10.2422 7.17572C10.3904 7.11913 10.54 7.07008 10.6909 7.02857C11.1065 6.91429 11.5325 6.85714 11.9688 6.85714C13.361 6.85714 14.5455 7.34026 15.5221 8.30649C16.4987 9.27273 16.987 10.4519 16.987 11.8442V11.9532L17.5481 11.3922C17.789 11.1512 18.1798 11.1512 18.4208 11.3922C18.6618 11.6332 18.6618 12.0239 18.4208 12.2649L17.0707 13.615Z"
        fill="white"
      />
    </svg>
  )
}

export const IconSending = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1822_10989)">
        <path
          d="M12.0545 20.4545C9.61818 20.4545 7.54545 19.6091 5.83636 17.9182C4.12727 16.2273 3.27273 14.1636 3.27273 11.7273V11.5364L2.23438 12.5747C1.84386 12.9652 1.21069 12.9652 0.820166 12.5747L0.707107 12.4617C0.316583 12.0711 0.316582 11.438 0.707107 11.0474L3.65653 8.09802C4.04705 7.70749 4.68022 7.70749 5.07074 8.09802L8.02017 11.0474C8.41069 11.438 8.41069 12.0711 8.02017 12.4617L7.90711 12.5747C7.51658 12.9652 6.88342 12.9652 6.49289 12.5747L5.45455 11.5364V11.7273C5.45455 13.5455 6.09545 15.0909 7.37727 16.3636C8.65909 17.6364 10.2182 18.2727 12.0545 18.2727C12.5273 18.2727 12.9909 18.2182 13.4455 18.1091C13.6728 18.0545 13.8979 17.9863 14.1207 17.9044C14.5248 17.756 14.9871 17.8235 15.2915 18.1278L15.4978 18.3342C15.9624 18.7988 15.8596 19.5808 15.2509 19.8282C14.9345 19.9568 14.6145 20.0656 14.2909 20.1545C13.5636 20.3545 12.8182 20.4545 12.0545 20.4545ZM20.3435 15.3565C19.9529 15.7471 19.3198 15.7471 18.9293 15.3565L15.9798 12.4071C15.5893 12.0166 15.5893 11.3834 15.9798 10.9929L16.0929 10.8798C16.4834 10.4893 17.1166 10.4893 17.5071 10.8798L18.5455 11.9182V11.7273C18.5455 9.90909 17.9045 8.36364 16.6227 7.09091C15.3409 5.81818 13.7818 5.18182 11.9455 5.18182C11.4727 5.18182 11.0091 5.23636 10.5545 5.34545C10.3272 5.40002 10.1021 5.46824 9.87925 5.55011C9.47523 5.69855 9.01289 5.63107 8.70853 5.32671L8.5022 5.12038C8.03761 4.65579 8.14038 3.87375 8.74906 3.62637C9.06547 3.49778 9.38548 3.38899 9.70909 3.3C10.4364 3.1 11.1818 3 11.9455 3C14.3818 3 16.4545 3.84545 18.1636 5.53636C19.8727 7.22727 20.7273 9.29091 20.7273 11.7273V11.9182L21.7656 10.8798C22.1561 10.4893 22.7893 10.4893 23.1798 10.8798L23.2929 10.9929C23.6834 11.3834 23.6834 12.0166 23.2929 12.4071L20.3435 15.3565Z"
          fill="#B3B2AD"
        />
      </g>
      <defs>
        <clipPath id="clip0_1822_10989">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconBell = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1235_14228)">
        <path
          d="M10.656 0.575989C10.32 0.911989 10.08 1.48799 10.08 1.91999C10.08 2.35199 9.55201 2.97599 8.88001 3.26399C7.20001 4.03199 5.76001 6.62399 5.76001 8.87999C5.76001 11.76 4.99201 13.92 3.31201 15.648C0.960014 18.048 1.92001 19.2 6.81601 19.92C8.73601 20.208 9.07201 20.4 9.31201 21.648C9.64801 23.472 11.472 24.384 13.2 23.616C13.968 23.28 14.544 22.512 14.688 21.648C14.928 20.4 15.264 20.208 17.184 19.92C22.08 19.2 23.04 18.048 20.688 15.648C19.008 13.92 18.24 11.76 18.24 8.87999C18.24 6.62399 16.8 4.03199 15.12 3.26399C14.448 2.97599 13.92 2.35199 13.92 1.91999C13.92 0.959988 13.008 -1.14441e-05 12 -1.14441e-05C11.568 -1.14441e-05 10.992 0.239988 10.656 0.575989ZM12.768 1.48799C13.248 2.30399 12.144 3.21599 11.472 2.54399C10.896 1.96799 11.28 0.959988 12 0.959988C12.24 0.959988 12.576 1.19999 12.768 1.48799ZM15.936 5.18399C16.944 6.38399 17.28 7.19999 17.28 8.78399C17.28 11.76 18.096 14.208 19.728 16.08L21.168 17.712L20.064 18.336C19.296 18.768 16.848 18.96 12 18.96C7.15201 18.96 4.70401 18.768 3.93601 18.336L2.83201 17.712L4.27201 16.08C5.90401 14.208 6.72001 11.76 6.72001 8.83199C6.72001 6.71999 8.06401 4.51199 9.64801 3.93599C10.128 3.74399 11.424 3.59999 12.528 3.64799C14.16 3.64799 14.784 3.98399 15.936 5.18399ZM13.824 21.168C13.488 23.52 10.512 23.52 10.176 21.168C10.032 20.256 10.224 20.16 12 20.16C13.776 20.16 13.968 20.256 13.824 21.168Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1235_14228">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const IconReminderFalse = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.9999 7.92859C3.68447 7.92859 3.42847 8.18459 3.42847 8.50002C3.42847 8.81545 3.68447 9.07145 3.9999 9.07145H11.9999C12.3153 9.07145 12.5713 8.81545 12.5713 8.50002C12.5713 8.18459 12.3153 7.92859 11.9999 7.92859H3.9999Z"
        fill="#EB5757"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.5C3.58171 0.5 0 4.08171 0 8.5C0 12.9171 3.58171 16.5 8 16.5C12.4183 16.5 16 12.9171 16 8.5C16 4.08171 12.4183 0.5 8 0.5ZM1.14286 8.5C1.14286 4.71314 4.21314 1.64286 8 1.64286C11.7869 1.64286 14.8571 4.71314 14.8571 8.5C14.8571 12.2886 11.7869 15.3571 8 15.3571C4.21314 15.3571 1.14286 12.2886 1.14286 8.5Z"
        fill="#EB5757"
      />
    </svg>
  )
}

export const IconReminderTrue = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8439 6.6069C12.0605 6.37718 12.0502 6.01547 11.8211 5.7989C11.5914 5.58232 11.2297 5.5926 11.0131 5.82174L7.11079 9.95145L4.9868 7.70403C4.77023 7.47489 4.40851 7.46461 4.1788 7.68118C3.94965 7.89776 3.93938 8.25947 4.15595 8.48919L6.69536 11.18C6.80336 11.2943 6.95364 11.3572 7.11079 11.3572C7.2685 11.3572 7.41881 11.2943 7.52624 11.18L11.8439 6.6069Z"
        fill="#219653"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 0.5C3.58171 0.5 0 4.08171 0 8.5C0 12.9171 3.58171 16.5 8 16.5C12.4183 16.5 16 12.9171 16 8.5C16 4.08171 12.4183 0.5 8 0.5ZM1.14286 8.5C1.14286 4.71314 4.21314 1.64286 8 1.64286C11.7869 1.64286 14.8571 4.71314 14.8571 8.5C14.8571 12.2886 11.7869 15.3571 8 15.3571C4.21314 15.3571 1.14286 12.2886 1.14286 8.5Z"
        fill="#219653"
      />
    </svg>
  )
}

export const IconWhatsapp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.9886 20.9463L12.88 19.9522L12.35 20.0101L12.1027 20.4825L12.9886 20.9463ZM6.45572 19.09L7.06966 19.8793L8.08109 19.0927L7.07226 18.3027L6.45572 19.09ZM4.23006 20.8211L3.61612 20.0317L3.61611 20.0317L4.23006 20.8211ZM20 12C20 16.1206 16.8838 19.5148 12.88 19.9522L13.0973 21.9404C18.1043 21.3933 22 17.1523 22 12H20ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM7.07226 18.3027C5.20015 16.8366 4 14.5587 4 12H2C2 15.1996 3.50381 18.0485 5.83917 19.8773L7.07226 18.3027ZM4.844 21.6104L7.06966 19.8793L5.84178 18.3006L3.61612 20.0317L4.844 21.6104ZM4.29145 20C5.1484 20 5.52041 21.0843 4.84401 21.6104L3.61611 20.0317C2.78939 20.6747 3.24408 22 4.29145 22V20ZM12 20H4.29145V22H12V20ZM12.9 20H12V22H12.9V20ZM12.1027 20.4825C12.2517 20.1979 12.5519 20 12.9 20V22C13.3252 22 13.6921 21.7586 13.8746 21.4102L12.1027 20.4825Z"
        fill="#0B0C0E"
      />
      <path
        d="M8.49139 7.38922C8.362 7.39831 8.23514 7.42991 8.11964 7.489C8.03299 7.53333 7.95255 7.5974 7.82616 7.71675C7.70681 7.82944 7.63764 7.92786 7.56476 8.02275C7.19506 8.50409 6.99636 9.09494 7.00005 9.70187C7.00206 10.1925 7.1295 10.6693 7.33076 11.1145C7.73889 12.0173 8.41195 12.9717 9.30101 13.8568C9.51457 14.0694 9.7238 14.2843 9.94941 14.4829C11.0529 15.4545 12.3679 16.1551 13.7898 16.5291C13.7898 16.5291 14.3498 16.6151 14.358 16.6156C14.5435 16.6256 14.7287 16.6122 14.9144 16.6027C15.2057 16.5877 15.4901 16.5089 15.7475 16.3718C15.913 16.2837 15.9913 16.2399 16.1302 16.1523C16.1302 16.1523 16.1728 16.1235 16.255 16.0623C16.39 15.9617 16.4734 15.8909 16.5857 15.7743C16.6685 15.6883 16.7397 15.5867 16.7947 15.4722C16.8729 15.309 16.9516 14.9975 16.9829 14.7388C17.0068 14.5412 16.9996 14.4332 16.997 14.3663C16.9927 14.2587 16.9038 14.148 16.8064 14.101L16.2249 13.8396C16.2249 13.8396 15.3554 13.4612 14.8236 13.2186C14.7682 13.1933 14.7076 13.1816 14.6467 13.1779C14.5133 13.1697 14.3638 13.2045 14.2687 13.3047C14.2637 13.3027 14.1975 13.3599 13.474 14.2364C13.4341 14.2841 13.3406 14.3878 13.1789 14.3781C13.1545 14.3764 13.1302 14.3729 13.1065 14.3667C13.041 14.3494 12.9772 14.3266 12.9148 14.3002C12.7911 14.2477 12.7477 14.2278 12.6632 14.1914C12.0859 13.9392 11.5561 13.6018 11.0878 13.1889C10.9622 13.0783 10.8454 12.9592 10.725 12.8425C10.3048 12.4352 9.96076 12.002 9.70484 11.5747C9.69087 11.5514 9.66934 11.5177 9.64615 11.48C9.60428 11.4119 9.5581 11.3309 9.54362 11.2753C9.50645 11.1282 9.60506 11.01 9.60506 11.01C9.60506 11.01 9.84846 10.7439 9.96155 10.5992C10.0704 10.4599 10.1643 10.3237 10.2241 10.2266C10.3419 10.0372 10.3792 9.84151 10.3173 9.69052C10.0367 9.00654 9.74725 8.32625 9.44893 7.64983C9.39005 7.51634 9.21492 7.41935 9.05566 7.40096C9.00172 7.39473 8.94782 7.38847 8.89366 7.38491C8.7596 7.37837 8.62527 7.37981 8.49139 7.38922Z"
        fill="#0B0C0E"
      />
    </svg>
  )
}

export const IconArrowLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
    >
      <path
        d="M0.25 5.59999C0.25 5.48828 0.311249 5.37657 0.433124 5.29148L6.68313 0.927923C6.86187 0.803125 7.13063 0.765598 7.36437 0.833233C7.59813 0.900432 7.75 1.06014 7.75 1.23643L7.75 9.96355C7.75 10.1398 7.59812 10.2995 7.36437 10.3667C7.13062 10.4344 6.86187 10.3969 6.68312 10.2721L0.433124 5.90849C0.311249 5.8234 0.25 5.71169 0.25 5.59999Z"
        fill="#0B0C0E"
      />
    </svg>
  )
}

export const IconWhatsapp2 = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9294 6.73634C11.3405 6.73772 9.81701 7.36916 8.69316 8.49217C7.5693 9.61517 6.93686 11.138 6.93457 12.7266C6.93457 13.8583 7.25168 14.9607 7.85047 15.9144L7.99391 16.1408L7.38907 18.3524L9.65636 17.7563L9.87497 17.8867C10.7983 18.4336 11.8519 18.7222 12.9251 18.7221H12.9268C16.2293 18.7221 18.9174 16.0345 18.9182 12.731C18.9207 11.9437 18.767 11.1638 18.4661 10.4363C18.1652 9.70879 17.723 9.04817 17.165 8.49266C16.6103 7.93439 15.9503 7.4917 15.2232 7.19022C14.4962 6.88875 13.7165 6.73448 12.9294 6.73634ZM16.4539 15.3019C16.3036 15.7226 15.5838 16.1062 15.2373 16.1581C14.8588 16.2229 14.4703 16.1986 14.1028 16.0872C13.7542 15.9786 13.4116 15.8518 13.0763 15.7071C11.2696 14.9279 10.0893 13.1085 9.99939 12.9884C9.90953 12.8675 9.26408 12.0122 9.26408 11.125C9.26408 10.2386 9.72894 9.8032 9.89484 9.62264C9.955 9.55421 10.0287 9.49894 10.1112 9.46029C10.1937 9.42164 10.2833 9.40044 10.3744 9.39803C10.4954 9.39803 10.6146 9.39803 10.72 9.40407C10.8306 9.40926 10.9792 9.36174 11.1253 9.71335C11.2747 10.0736 11.6351 10.96 11.6809 11.0507C11.7258 11.1405 11.7552 11.2459 11.6955 11.366C11.6351 11.4861 11.6057 11.5612 11.515 11.6666C11.4251 11.7712 11.3257 11.9007 11.2454 11.982C11.1546 12.0718 11.0613 12.1686 11.1659 12.3491C11.2713 12.5297 11.6325 13.1188 12.1682 13.5966C12.856 14.21 13.4366 14.4 13.6163 14.4907C13.7969 14.5806 13.9015 14.5659 14.0069 14.4458C14.1123 14.3249 14.4571 13.9197 14.5772 13.7391C14.6981 13.5594 14.8174 13.5888 14.9833 13.6493C15.1475 13.7098 16.0331 14.1452 16.2137 14.235C16.3934 14.3257 16.5135 14.3706 16.5593 14.4458C16.6034 14.521 16.6034 14.8812 16.4539 15.3019Z"
        fill="white"
      />
      <path
        d="M12.6633 0.982666C6.03074 0.982666 0.652832 6.35786 0.652832 12.9892C0.652832 19.6205 6.03074 24.9974 12.6633 24.9974C19.2958 24.9974 24.6728 19.6205 24.6728 12.9892C24.6728 6.35786 19.2958 0.982666 12.6633 0.982666ZM12.9268 19.9393H12.9242C11.7206 19.9391 10.5361 19.6373 9.4792 19.0615L5.65747 20.0637L6.68051 16.329C6.04859 15.2336 5.71658 13.9911 5.71795 12.7265C5.71968 8.7526 8.95386 5.51901 12.9268 5.51901C14.8554 5.51901 16.6656 6.27061 18.0265 7.63298C18.6977 8.30129 19.2297 9.09602 19.5917 9.9712C19.9537 10.8464 20.1386 11.7847 20.1357 12.7317C20.1339 16.7057 16.8997 19.9393 12.9268 19.9393Z"
        fill="white"
      />
    </svg>
  )
}

export const IconInstagram = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5952 14.2619C13.1641 14.2619 13.7096 14.0359 14.1118 13.6337C14.514 13.2315 14.74 12.686 14.74 12.1172C14.74 11.5483 14.514 11.0028 14.1118 10.6006C13.7096 10.1984 13.1641 9.97241 12.5952 9.97241C12.0264 9.97241 11.4809 10.1984 11.0787 10.6006C10.6765 11.0028 10.4505 11.5483 10.4505 12.1172C10.4505 12.686 10.6765 13.2315 11.0787 13.6337C11.4809 14.0359 12.0264 14.2619 12.5952 14.2619Z"
        fill="white"
      />
      <path
        d="M15.2764 6.85254H9.91504C9.1352 6.85254 8.45294 7.09612 8.01423 7.53492C7.57551 7.97372 7.33197 8.6561 7.33197 9.43608V14.7984C7.33197 15.5784 7.57551 16.2608 8.06259 16.7488C8.55053 17.1876 9.18356 17.4312 9.96341 17.4312H15.2764C16.0562 17.4312 16.7385 17.1876 17.1772 16.7488C17.6643 16.31 17.9087 15.6276 17.9087 14.8476V9.48532C17.9087 8.70533 17.6643 8.07132 17.2264 7.58329C16.7385 7.09612 16.1046 6.85254 15.2764 6.85254ZM12.5957 15.4324C11.7169 15.4315 10.8744 15.0819 10.253 14.4604C9.63157 13.8389 9.28206 12.9962 9.28115 12.1172C9.28206 11.2383 9.63157 10.3956 10.253 9.77407C10.8744 9.15256 11.7169 8.80299 12.5957 8.80208C14.3989 8.80208 15.9586 10.2653 15.9586 12.1172C15.9586 13.97 14.4473 15.4324 12.5957 15.4324ZM16.0562 9.43608C15.9534 9.43748 15.8514 9.41826 15.7561 9.37955C15.6609 9.34085 15.5744 9.28345 15.5017 9.21074C15.429 9.13803 15.3716 9.05149 15.3329 8.95623C15.2942 8.86097 15.275 8.75891 15.2764 8.6561C15.2764 8.2173 15.6175 7.87611 16.0562 7.87611C16.4949 7.87611 16.8361 8.2173 16.8361 8.6561C16.8374 8.75891 16.8182 8.86097 16.7795 8.95623C16.7408 9.05149 16.6834 9.13803 16.6107 9.21074C16.5381 9.28345 16.4515 9.34085 16.3563 9.37955C16.261 9.41826 16.159 9.43748 16.0562 9.43608Z"
        fill="white"
      />
      <path
        d="M12.6933 0.027832C6.0642 0.027832 0.702881 5.39014 0.702881 12.0196C0.702881 18.6499 6.0642 24.0122 12.6933 24.0122C19.3216 24.0122 24.6829 18.6499 24.6829 12.0196C24.7312 5.39014 19.3216 0.027832 12.6933 0.027832ZM19.078 14.8467C19.078 15.9688 18.6877 16.9431 18.0054 17.6255C17.3232 18.3079 16.3481 18.6499 15.2764 18.6499H9.96256C8.88995 18.6499 7.91579 18.3087 7.23354 17.6255C6.50206 16.9431 6.16093 15.9688 6.16093 14.8476V9.48442C6.16093 7.24207 7.67225 5.68209 9.96256 5.68209H15.3239C16.4449 5.68209 17.3707 6.07252 18.0529 6.7549C18.7352 7.43728 19.0772 8.36325 19.0772 9.48442V14.8476L19.078 14.8467Z"
        fill="white"
      />
    </svg>
  )
}

export const IconHamburger = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 5.83325H17.5"
        stroke="#30302D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2.5 10H17.5"
        stroke="#30302D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2.5 14.1667H17.5"
        stroke="#30302D"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const IconGoogle = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5928 0.0202637C5.96512 0.0202637 0.592773 5.37981 0.592773 11.9901C0.592773 18.6004 5.96512 23.9599 12.5928 23.9599C19.2195 23.9599 24.5928 18.6004 24.5928 11.9901C24.5928 5.37981 19.2195 0.0202637 12.5928 0.0202637ZM12.7327 18.9908C11.8132 18.991 10.9028 18.8101 10.0533 18.4584C9.20386 18.1066 8.43201 17.591 7.78189 16.9409C7.13177 16.2907 6.61611 15.5189 6.26438 14.6694C5.91265 13.82 5.73173 12.9095 5.73197 11.9901C5.73173 11.0707 5.91265 10.1602 6.26438 9.31076C6.61611 8.46129 7.13177 7.68945 7.78189 7.03932C8.43201 6.3892 9.20386 5.87355 10.0533 5.52181C10.9028 5.17008 11.8132 4.98917 12.7327 4.98941C14.6234 4.98941 16.2024 5.68426 17.4147 6.81432L15.4408 8.78735V8.78278C14.7066 8.08243 13.774 7.72403 12.7327 7.72403C10.4232 7.72403 8.54523 9.67512 8.54523 11.9855C8.54523 14.2959 10.4232 16.2516 12.7327 16.2516C14.8291 16.2516 16.2554 15.0529 16.5489 13.4081H12.7327V10.6772H19.3183C19.4051 11.1453 19.4536 11.6354 19.4536 12.1474C19.4536 16.1464 16.7747 18.9908 12.7327 18.9908Z"
        fill="white"
      />
    </svg>
  )
}

export const IconCheck = () => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3334 1L5.00008 8.33333L1.66675 5"
        stroke="#EC7A30"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
