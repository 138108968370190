import { Routes, Route, useLocation } from "react-router-dom"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import TestInvitationPage from "./pages/TestInvitation/TestInvitationPage"
import TestInvitationStatusPage from "./pages/TestInvitation/TestInvitationStatusPage"
import PageWrapper from "./pages/PageWrapper"

const App = () => {
  const location = useLocation()
  const { i18n } = useTranslation()

  useEffect(() => {
    if (location.search.includes("?lan=en")) {
      i18n.changeLanguage("en")
      document.getElementsByClassName('lang-button')[0].children[0].setAttribute('selected', 'selected')
      document.getElementsByClassName('lang-button')[0].children[1].removeAttribute('selected')
    } else if (location.search.includes("?lan=ar")) {
      i18n.changeLanguage("ar")
      document.getElementsByClassName('lang-button')[0].children[1].setAttribute('selected', 'selected')
      document.getElementsByClassName('lang-button')[0].children[0].removeAttribute('selected')
    }
  }, [location])

  return (
    <Routes>
      <Route path="/" element={<PageWrapper />}>
        <Route index element={<TestInvitationPage />} />
      </Route>
      <Route path="/status" element={<PageWrapper />}>
        <Route index element={<TestInvitationStatusPage />} />
      </Route>
    </Routes>
  )
}

export default App
