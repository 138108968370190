import { useEffect, useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import PhoneInput from "react-phone-input-2"
import { useNavigate } from "react-router"
import { useAppDispatch, useAppSelector } from "@/redux/store"
import {
  sendTestInvitation,
  setLoading,
} from "@/redux/slices/requests/allSlice"
import TestField from "@/components/Test/TestField"
import "react-phone-input-2/lib/style.css"

type Personal = {
  first_name: string
  suffix: string
  phone: string
  fullPhone: string
}

const TestInvitationPage = () => {
  let defaultPersonal = {
    first_name: "",
    suffix: "المكرمة",
    phone: "+966",
    fullPhone: "",
  }
  const { i18n } = useTranslation()
  const [personal, setPersonal] = useState<Personal | any>(defaultPersonal)
  const [frontError, setFrontError] = useState<any>({})
  const [phone, setPhone] = useState("")
  const navigate = useNavigate()
  const [validation, setValidation] = useState([])
  const checkEmptyInput = (value: string) => {
    if (!!!value) {
      return "empty"
    }
  }
  const { t } = useTranslation()
  const { error } = useAppSelector((state) => state.all)
  const dispatch = useAppDispatch()

  const options = [
    { value: "المكرمة", label: "المكرمة", id: 0 },
    { value: "المكرم", label: "المكرم", id: 1 },
    { value: "الاستاذ", label: "الاستاذ", id: 2 },
    { value: "الاستاذة", label: "الاستاذة", id: 3 },
    { value: "الدكتور", label: "الدكتور", id: 4 },
    { value: "الدكتورة", label: "الدكتورة", id: 5 },
    { value: "الشيخ", label: "الشيخ", id: 6 },
    { value: "الشيخة", label: "الشيخة", id: 7 },
    { value: "Mr", label: "Mr", id: 8 },
    { value: "Mrs", label: "Mrs", id: 9 },
    { value: "Dr", label: "Dr", id: 10 },
  ]

  const onSubmit = () => {
    setPersonal({ ...personal, fullPhone: personal.phone + phone })
    if (personal.first_name.length < 1 && phone.length < 1) {
      setFrontError({
        first_name: ["Name is required"],
        phone: ["Phone is required"],
      })
    } else if (personal.first_name.length < 1) {
      setFrontError({ first_name: ["Name is required"] })
    } else if (phone.length < 1) {
      setFrontError({ phone: ["Phone is required"] })
    } else {
      dispatch(setLoading())
      dispatch(
        sendTestInvitation({
          first_name: personal.first_name,
          suffix: personal.suffix,
          phone: personal.fullPhone,
          language: i18n.language ? i18n.language : 'ar'
        }),
      ).then((res: any) => {
        if (!res.error) {
          setPersonal(defaultPersonal)
          navigate("/status")
        } else {
          console.log(error)
        }
      })
    }
  }

  useEffect(() => {
    setValidation(validation)
  }, [dispatch])

  useEffect(() => {
    const input = document.getElementsByClassName("form-control")
    input[0].setAttribute("disabled", "disabled")
  }, [])

  const checkInput = (e: any) => {
    e.target.value.length < 12 && setPhone(e.target.value)
    setPersonal({ ...personal, fullPhone: personal.phone + e.target.value })
  }

  return (
    <>
      <div className="test-container">
        <div className="test-container__text">
          <h2 className="test-container__title">{t("test.title")}</h2>
          <span className="test-container__description">
            {t("test.description")}
          </span>
        </div>
        <div
          className={`form-container ${
            Object.keys(error).length > 0
              ? Object.keys(error).length === 4
                ? "two"
                : "one"
              : ""
          }`}
        >
          <div
            className={`image-container ${
              Object.keys(frontError).length > 0 ? "error" : ""
            }
           
            `}
          ></div>
          <div className="test-col__body">
            <form className="test-form test-form--password" autoComplete="off">
              <div className="test-select">
                <Select
                  defaultValue={options[0]}
                  options={options}
                  // menuIsOpen={true}
                  isSearchable={false}
                  value={options.find((e) => e.value === personal.suffix)}
                  onChange={(val) => {
                    setPersonal({ ...personal, suffix: val?.value })
                  }}
                  styles={{
                    container: (state) => ({
                      ...state,
                      outline: "none",
                      border: "0",
                      position: "relative",
                      boxShadow: "none",
                    }),
                    valueContainer: (state) => ({
                      ...state,
                      display: "flex",
                      padding: "0 0 8px 0",
                    }),
                    singleValue: (state) => ({
                      ...state,
                      color: "#979797",
                      fontSize: "15px",
                      lineHeight: "21px",
                      fontFamily: "Manrope",
                    }),
                    control: (state) => ({
                      ...state,
                      fontSize: "15px",
                      lineHeight: "21px",
                      fontFamily: "Manrope",
                      backgroundColor: "#111111",
                      color: "#979797",
                      outline: "none",
                      border: "none",
                      boxShadow: "none",
                      minHeight: "21px",
                      borderRadius: "0",
                      borderWidth: "2px",
                      borderBottom: "2px solid #979797",
                      "&:hover": {
                        borderBottom: "2px solid #979797",
                      },
                    }),
                    indicatorSeparator: (state) => ({
                      display: "none",
                    }),

                    dropdownIndicator: (base, state) => ({
                      ...base,
                      transform: state.selectProps.menuIsOpen
                        ? "rotate(180deg)"
                        : "",
                      padding: state.selectProps.menuIsOpen
                        ? "8px 0 0 0px"
                        : "0 0 8px 0px",
                    }),
                    option: (defaultStyles, state) => ({
                      ...defaultStyles,
                      fontSize: "15px",
                      lineHeight: "21px",
                      color: "#7111111",
                      "&:not(:last-of-type):not(:first-of-type)": {
                        borderBottom: "1px solid #F9F9F9",
                        padding: "15px 0px",
                      },
                      "&:last-of-type": {
                        padding: "15px 0px 0px",
                      },
                      "&:first-of-type": {
                        borderBottom: "1px solid #F9F9F9",
                        padding: "0px 0px 15px",
                      },
                      backgroundColor: "#FFF",
                      "&:hover": {
                        background: "#FFF",
                      },
                      "&::after": {
                        content: state.isSelected
                          ? "url(../../../public/images/check.svg)"
                          : '""',
                        position: "absolute",
                        right: "20px",
                        width: "16px",
                        height: "16px",
                      },
                    }),
                    menu: (state) => ({
                      ...state,
                      boxShadow: "0px 0px 20px 0px #4F4F4F1F",
                    }),
                    menuList: (state) => ({
                      ...state,
                      borderRadius: "10px",
                      paddingRight: "9px",
                      paddingBottom: "0",
                      boxShadow: "0",
                      paddingTop: "0",
                      maxHeight: "176px",
                      margin: "20px 9px 20px 20px",
                      "::-webkit-scrollbar": {
                        width: "1px",
                        height: "90%",
                        paddingRight: "9px",
                        border: "0",
                        outline: "none",
                      },
                      "::-webkit-scrollbar-track": {
                        background: "lightgray",
                      },
                      "::-webkit-scrollbar-thumb": {
                        background: "#111111",
                      },
                      "::-webkit-scrollbar-thumb:hover": {
                        background: "lightgray",
                      },
                    }),
                  }}
                />
                <label className={"active"} htmlFor="">
                  {t("test.suffix")}
                </label>
                {error.suffix && (
                  <span className="input-item__error error mt-1">
                    {error["suffix"]}
                  </span>
                )}
              </div>
              <TestField
                name={"first_name"}
                type="text"
                disabled={false}
                label={`${t("test.name")}`}
                value={personal.first_name}
                error={
                  frontError.first_name
                    ? frontError.first_name
                    : error?.first_name
                }
                className={checkEmptyInput(personal.first_name)}
                onHandleChange={(value) => {
                  setPersonal({
                    ...personal,
                    first_name: value,
                  })
                }}
              />
              <div
                className="test-phone"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <PhoneInput
                  placeholder=""
                  countryCodeEditable={false}
                  enableSearch={true}
                  disableSearchIcon={true}
                  value={personal.phone}
                  onChange={(value) => {
                    setPersonal({
                      ...personal,
                      phone: value,
                    })
                  }}
                />
                <input
                  type="number"
                  value={phone}
                  placeholder="5xxxxxxx"
                  className="phone-input-2"
                  onChange={(event) => {
                    checkInput(event)
                  }}
                />
                <label className={"active"} htmlFor="">
                  {t("test.phone")}
                </label>
                {error.phone && (
                  <span className="input-item__error error mt-1"
                  style={{ position: "absolute", top: "59px" }}>
                    {error["phone"]}
                  </span>
                )}
                {frontError?.phone && (
                  <span
                    className="input-item__error error mt-1"
                    style={{ position: "absolute", top: "59px" }}
                  >
                    {frontError?.phone}
                  </span>
                )}
              </div>
            </form>
            <div className="test-form__btn">
              <button className="test-send__btn" onClick={onSubmit}>
                {t("test.send")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TestInvitationPage
