import React from "react"
import styles from "./MainContent.module.scss"

type Props = {
  children: React.ReactNode
}

const MainContent = ({ children }: Props) => {
  return <main className={styles.mainContent}>{children}</main>
}

export default MainContent
